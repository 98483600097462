import { PayloadAction, createSlice } from "@reduxjs/toolkit"

const initialState: any = {
  productsList: [],
  productsListLoading: true,
  subCatsList: [],
}

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    updateProductsList: (state: any, action: PayloadAction<any>) => {
      state.productsList = [...action.payload.list]
      state.productsListLoading = false
    },
    setSubcatsList: (state: any, action: PayloadAction<any>) => {
      state.subCatsList = action.payload.data.data.serviceCategories
    },
    resetSubcatsList: (state: any) => {
      state.subCatsList = []
    }
  },
})

export const { updateProductsList, setSubcatsList, resetSubcatsList } = productsSlice.actions

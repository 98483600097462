import { api } from "./index"

export const mainApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getServiceList: builder.mutation<any, void>({
      query: () => ({
        url: "/public/service-categories/selectlist",
        method: "GET",
      }),
    }),
    getServiceInfo: builder.mutation<any, string>({
      query: (id) => ({
        url: `/public/service-categories/${id}`,
        method: "GET",
      }),
    }),
    getProductsList: builder.mutation<any, any>({
      query: (data) => ({
        url: `/public/services/getlist`,
        method: "POST",
        body: data,
      }),
    }),
    getProductInfo: builder.mutation<any, string>({
      query: (id) => ({
        url: `/public/services/${id}`,
        method: "GET",
      }),
    }),
    postReqForNewProduct: builder.mutation<any, { text: string; technical_object_id?: string }>({
      query: (data) => ({
        url: `/public/services/propose`,
        method: "POST",
        body: data,
      }),
    }),
    getTechObjectList: builder.mutation<any, void>({
      query: (data) => ({
        url: `/public/technical-object/getlist`,
        method: "POST",
        body: data,
      }),
    }),
    getRoomServices: builder.mutation<any, any>({
      query: (data) => ({
        url: `/public/technical-object/services/${data.technical_object_id}`,
        method: "POST",
        body: {
          category_id: data.category_id,
        },
      }),
    }),
    getRoomServicesList: builder.mutation<any, any>({
      query: ({technical_object_id}) => ({
        url: `/public/technical-object/service-categories/${technical_object_id}`,
        method: "POST",
        // body: {},
      }),
    }),
    getRoomSubcatList: builder.mutation<any, any>({
      query: (data) => ({
        url: `/public/services/getsubcatlist`,
        method: "POST",
        body: data,
      }),
    }),
    // Дочерние элементы объекта
    getChildObjects: builder.mutation<any, any>({
      query: ({serviceId, parentId, keywords}) => ({
        url: `/public/services/get-to-childs/${serviceId}${keywords ?`?keywords=${keywords}` : `?parent_id=${parentId}`}`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetServiceListMutation,
  useGetProductsListMutation,
  useGetServiceInfoMutation,
  useGetProductInfoMutation,
  usePostReqForNewProductMutation,
  useGetTechObjectListMutation,
  useGetRoomServicesMutation,
  useGetRoomServicesListMutation,
  useGetRoomSubcatListMutation,
  useGetChildObjectsMutation,
} = mainApi

import React, { useEffect, useState } from "react"
import styles from "./Modal.module.scss"
import { selectAuth } from "../../redux/slice/auth"
import { useAppSelector } from "../../hooks"

interface Props {
  open: boolean
  setOpen: any
  children?: any
  isOrder?: boolean
  refContent?: any
  addClass?: string
  contentClass?: string
}

const Modal: React.FC<Props> = ({
  children,
  open,
  addClass,
  setOpen,
  isOrder = false,
  refContent = null,
  contentClass,
  ...props
}) => {
  const [appendClass, setAppendClass] = useState(false)
  const user = useAppSelector(selectAuth)

  useEffect(() => {
    if (open) setAppendClass(true)
    else setAppendClass(false)

    const body = document.getElementsByTagName("body")[0]
    if (open) {
      body.classList.add("noscroll")
    } else if (body.classList.contains("noscroll") && open === false) {
      body.classList.remove("noscroll")
    }
  }, [open])

  return open ? (
    <div
      className={`modal ${styles.modal} ${isOrder ? styles.modal_order : ""} ${addClass ? styles[addClass] : ""}`}
      onClick={(e: any) => {
        if (e.target.closest(`.${styles.modal__content}`)) return
        if (!user.token) return
        setOpen(false)
        document.body.classList.remove("noscroll")
      }}
    >
      <div className={`modal__wp ${styles.modal__wp} ${addClass ? styles[`${addClass}__wp`] : ""}`}>
        <div className={`modal__wrapper ${styles.modal__wrapper}`}>
          <div className={`modal__body ${styles.modal__body} ${addClass ? styles[`${addClass}__body`] : ""}`}>
            <div
              className={`modal__content ${styles.modal__content} ${appendClass ? styles.modal__content_opened : ""} ${
                contentClass ?? ""
              }`}
              ref={refContent}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default Modal
